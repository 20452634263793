import "../../App.css";
import './Resume.css';
import React from 'react';

function ResumeItems() {

return (
        <div className='resume'>
{/*             <video src={Video} alt="background - Video by Pressmaster from Pexels" autoPlay loop muted />*/}
            <div className="resume-pages">
{/*                          <Document
                            //file={pdfFile}
                        >
                            <Page pageNumber={1} />
                        </Document>
                        <Document
                            //file={pdfFile}
                        >
                            <Page pageNumber={2} />
                        </Document> */}
            </div>
        </div>
    )
}

export default ResumeItems