import React from 'react';
import '../../App.css';
import ProjectsList from '../projects/ProjectsList';

function Projects() {
    return (
        <div>
            <ProjectsList />
        </div>
    )
}

export default Projects