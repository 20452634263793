import '../../App.css';
import React from 'react';
import ResumeItems from '../resume/ResumeItems';

function Resume() {
  return (
    <ResumeItems />
  );
}

export default Resume